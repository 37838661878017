// public/style.css

// this builds the style.css which is loaded asyncronously through loadCSS

// normalize.css
@import "normalize/normalize";

// settings
@import "settings/variables";

// typography
@import "typography/headings";
@import "typography/copy";

// bootstrap css grid implementation
@import "grid/grid";
