// Bootstrap CSS Grid Module Implementation

* {
	box-sizing: border-box;
}

.container,
.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.row {
	margin-right: -15px;
	margin-left: -15px;
	& > [class^="col"],
	& > [class*=" col"] {
		position: relative;
		width: 100%;
		min-height: 1px;
		padding: 15px;
		grid-column: span 12;
	}
}

// `.col-{i}` and col-xs-{i}
.row {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 0;
	.col-1,
	.col-xs-1 {
		grid-column: span 1;
	}
	.col-2,
	.col-xs-2 {
		grid-column: span 2;
	}
	.col-3,
	.col-xs-3 {
		grid-column: span 3;
	}
	.col-4,
	.col-xs-4 {
		grid-column: span 4;
	}
	.col-5,
	.col-xs-5 {
		grid-column: span 5;
	}
	.col-6,
	.col-xs-6 {
		grid-column: span 6;
	}
	.col-7,
	.col-xs-7 {
		grid-column: span 7;
	}
	.col-8,
	.col-xs-8 {
		grid-column: span 8;
	}
	.col-9,
	.col-xs-9 {
		grid-column: span 9;
	}
	.col-10,
	.col-xs-10 {
		grid-column: span 10;
	}
	.col-11,
	.col-xs-11 {
		grid-column: span 11;
	}
	.col-12,
	.col-xs-12 {
		grid-column: span 12;
	}
}

// `.col-sm-{i}`
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
	.row {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: 0;
		.col-sm-1 {
			grid-column: span 1;
		}
		.col-sm-2 {
			grid-column: span 2;
		}
		.col-sm-3 {
			grid-column: span 3;
		}
		.col-sm-4 {
			grid-column: span 4;
		}
		.col-sm-5 {
			grid-column: span 5;
		}
		.col-sm-6 {
			grid-column: span 6;
		}
		.col-sm-7 {
			grid-column: span 7;
		}
		.col-sm-8 {
			grid-column: span 8;
		}
		.col-sm-9 {
			grid-column: span 9;
		}
		.col-sm-10 {
			grid-column: span 10;
		}
		.col-sm-11 {
			grid-column: span 11;
		}
		.col-sm-12 {
			grid-column: span 12;
		}
	}
}

// `.col-md-{i}`
@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
	.row {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: 0;
		.col-md-1 {
			grid-column: span 1;
		}
		.col-md-2 {
			grid-column: span 2;
		}
		.col-md-3 {
			grid-column: span 3;
		}
		.col-md-4 {
			grid-column: span 4;
		}
		.col-md-5 {
			grid-column: span 5;
		}
		.col-md-6 {
			grid-column: span 6;
		}
		.col-md-7 {
			grid-column: span 7;
		}
		.col-md-8 {
			grid-column: span 8;
		}
		.col-md-9 {
			grid-column: span 9;
		}
		.col-md-10 {
			grid-column: span 10;
		}
		.col-md-11 {
			grid-column: span 11;
		}
		.col-md-12 {
			grid-column: span 12;
		}
	}
}

// `.col-lg-{i}`
@media (min-width: 992px) {
	.container {
		max-width: 970px;
	}
	.row {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: 0;
		.col-lg-1 {
			grid-column: span 1;
		}
		.col-lg-2 {
			grid-column: span 2;
		}
		.col-lg-3 {
			grid-column: span 3;
		}
		.col-lg-4 {
			grid-column: span 4;
		}
		.col-lg-5 {
			grid-column: span 5;
		}
		.col-lg-6 {
			grid-column: span 6;
		}
		.col-lg-7 {
			grid-column: span 7;
		}
		.col-lg-8 {
			grid-column: span 8;
		}
		.col-lg-9 {
			grid-column: span 9;
		}
		.col-lg-10 {
			grid-column: span 10;
		}
		.col-lg-11 {
			grid-column: span 11;
		}
		.col-lg-12 {
			grid-column: span 12;
		}
	}
}

// `.col-xl-{i}`
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
	.row {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: 0;
		.col-xl-1 {
			grid-column: span 1;
		}
		.col-xl-2 {
			grid-column: span 2;
		}
		.col-xl-3 {
			grid-column: span 3;
		}
		.col-xl-4 {
			grid-column: span 4;
		}
		.col-xl-5 {
			grid-column: span 5;
		}
		.col-xl-6 {
			grid-column: span 6;
		}
		.col-xl-7 {
			grid-column: span 7;
		}
		.col-xl-8 {
			grid-column: span 8;
		}
		.col-xl-9 {
			grid-column: span 9;
		}
		.col-xl-10 {
			grid-column: span 10;
		}
		.col-xl-11 {
			grid-column: span 11;
		}
		.col-xl-12 {
			grid-column: span 12;
		}
	}
}
